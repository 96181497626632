import { isObject, trimStart } from 'lodash';
import slugBlackList from '@wix/communities-blog-universal/dist/src/constants/slug-black-list';

export const appRoutes = slugBlackList.filter(slug => slug !== 'create-post');
const getFirstSlug = route =>
  trimStart(route, '/')
    .split('/')[0]
    .split('?')[0];

export const isAppRoute = (route, routes = appRoutes) => {
  const pathname = isObject(route) ? route.pathname : route;
  const slug = getFirstSlug(pathname);
  return slug === '' || routes.includes(slug);
};
export const isPostPageRoute = route => !isAppRoute(route);
