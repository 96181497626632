import { omit, trimStart } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import URI from 'urijs';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { BLOG_PAGE_ID, BLOG_POST_PAGE_ID } from '@wix/communities-blog-client-common/dist/src/constants/component-ids';
import { connect } from '../runtime-context';
import { isSite } from '../../store/basic-params/basic-params-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import { isAppRoute } from '../../services/section-route-utils';
import getOuterUrl from '../../services/get-outer-url';
import Wix from '../../services/wix-sdk-polyfill';

const OMIT_PROPS = ['dispatch', 'titleFontClassName', 'tReady', 'lng', 'contentFontClassNameWithStyle'];

const InternalLink = ({
  addHoverClasses,
  children,
  className,
  to,
  topology: { sectionUrl: feedSectionUrl, postPageSectionUrl },
  isSite,
  useCustomRouter,
  sectionId,
  ...props
}) => {
  const shouldNavigateToFeed = sectionId === BLOG_PAGE_ID || isAppRoute(to);
  const sectionUrl = shouldNavigateToFeed ? feedSectionUrl : postPageSectionUrl;
  const route = new URI(getOuterUrl(to, sectionUrl)).toString();

  const linkClassName = classNames(addHoverClasses && 'blog-link-hover-color', className);
  const linkProps = omit(props, OMIT_PROPS);

  const handleClick = event => {
    if (!isSite) {
      event.preventDefault();
      Wix.Utils.navigateToSection(
        {
          appDefinitionId: BLOG_APP_ID,
          sectionId: shouldNavigateToFeed ? BLOG_PAGE_ID : BLOG_POST_PAGE_ID,
          shouldRefreshIframe: false,
        },
        trimStart(route, '/'),
      );
    }
  };

  return (
    <a href={route} onClick={handleClick} className={linkClassName} {...linkProps}>
      {children}
    </a>
  );
};

InternalLink.propTypes = {
  push: PropTypes.func,
  to: PropTypes.string,
  children: PropTypes.node,
  addHoverClasses: PropTypes.bool,
  topology: PropTypes.object,
  isSite: PropTypes.bool,
  sectionId: PropTypes.string,
};

InternalLink.defaultProps = {
  addHoverClasses: true,
};

const mapRuntimeToProps = state => ({
  topology: getTopology(state),
  isSite: isSite(state),
});

export default connect(mapRuntimeToProps)(InternalLink);
